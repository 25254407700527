import { Injectable } from '@angular/core';
import { CorrectionRequestReviewStatusCd, User } from '@xpo-ltl/sdk-common';
import {
  GetDimensionerInspectionPath,
  GetDimensionerInspectionResp,
  InspectionsApiService,
  ListCorrectionRequestsQuery,
  ListCorrectionRequestsResp,
  ReviewCorrectionRequestPath,
  ReviewCorrectionRequestResp,
  ReviewCorrectionRequestRqst,
  UpdateCorrectionRequestDimensionsForDensityPath,
  UpdateCorrectionRequestDimensionsForDensityResp,
  UpdateCorrectionRequestDimensionsForDensityRqst,
} from '@xpo-ltl/sdk-inspections';
import { EMPTY, Observable } from 'rxjs';
import { catchError, concatMap, distinctUntilChanged, tap } from 'rxjs/operators';
import { ApiUtil } from '../../classes/utils/api-util';
import { ErrorMessageActions } from '../../enums/error-message-actions.enum';
import { RetryStrategyService } from '../../operators/retry-strategy.service';
import { RequestValidator } from '../../validators/request.validator';
import { AppNavigationService } from '../app-navigation.service';
import { ErrorHandlingService } from '../error-handling.service';
import { ShipmentDetailsService } from './shipment-details.service';
import { InspectionError } from '../../classes/error/inspection-error';

@Injectable({
  providedIn: 'root'
})
export class InspectionsApiCorrectionsWrapperService {
  constructor(
    private inspectionsApiService: InspectionsApiService,
    private retryStrategyService: RetryStrategyService,
    private appNavigationService: AppNavigationService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  getCorrections(startDate: Date, endDate: Date, isOnlyInReviewStatus: boolean): Observable<ListCorrectionRequestsResp> {
    if (endDate < startDate) {
      const errorMessage: string = RequestValidator.END_START_DATE_ERROR_MESSAGE;
      throw new InspectionError(errorMessage);
    }

    const reqQuery: ListCorrectionRequestsQuery = new ListCorrectionRequestsQuery();
    reqQuery.reviewStatusCds = isOnlyInReviewStatus ? [CorrectionRequestReviewStatusCd.IN_REVIEW] : Object.values(CorrectionRequestReviewStatusCd);
    reqQuery.startDate = startDate?.toLocaleDateString();
    reqQuery.endDate = endDate?.toLocaleDateString();

    return this.inspectionsApiService.listCorrectionRequests(reqQuery, ApiUtil.DEFAULT_DATA_OPTIONS).pipe(
      this.retryStrategyService.retryStrategy(
        ShipmentDetailsService.DEFAULT_GET_TIMEOUT,
        ShipmentDetailsService.DEFAULT_RETRY_DELAY,
        ShipmentDetailsService.DEFAULT_RETRY_COUNT,
        ShipmentDetailsService.DEFAULT_LOADING_MESSAGE,
        'GET-CORRECTION-LIST'
      ),
      distinctUntilChanged(),
      catchError((error) => {
        console.error('error while getting correction list', error);
        const useMoreInfoMessage: boolean = true;
        // We need to navigate on an error back to the list page, in case we started from the shipment details already
        this.appNavigationService.navigateToList(); // Navigate back to list

        if (error.code === '500') {
          this.errorHandlingService.handleResponseError(
            error,
            ErrorMessageActions.GETTING,
            `shipment details`,
            useMoreInfoMessage
          );
        } else {
          this.errorHandlingService.handleResponseError(
            error,
            ErrorMessageActions.GETTING,
            `shipment details`,
            !useMoreInfoMessage
          );
        }

        return EMPTY;
      })
    );
  }
  
  getDimensionerInspection(dimensionerInspectionId: number): Observable<GetDimensionerInspectionResp> {
    RequestValidator.validateNumberIsPositiveOrZeroInteger(dimensionerInspectionId, 'Dimensioner Inspection ID');

    const reqQuery: GetDimensionerInspectionPath = new GetDimensionerInspectionPath();
    reqQuery.dimensionerInspectionId = dimensionerInspectionId;

    return this.inspectionsApiService.getDimensionerInspection(reqQuery, ApiUtil.DEFAULT_DATA_OPTIONS).pipe(
      this.retryStrategyService.retryStrategy(
        // TODO Move default values to a common enum
        ShipmentDetailsService.DEFAULT_GET_TIMEOUT,
        ShipmentDetailsService.DEFAULT_RETRY_DELAY,
        ShipmentDetailsService.DEFAULT_RETRY_COUNT,
        ShipmentDetailsService.DEFAULT_LOADING_MESSAGE,
        'GET-DIMENSIONER-INSPECTION'
      ),
      distinctUntilChanged(),
      catchError((error) => {
        const useMoreInfoMessage: boolean = true;

        if (error.code === '500') {
          this.errorHandlingService.handleResponseError(
            error,
            ErrorMessageActions.GETTING,
            `dimensioner inspection`,
            useMoreInfoMessage
          );
        } else {
          this.errorHandlingService.handleResponseError(
            error,
            ErrorMessageActions.GETTING,
            `dimensioner inspection`,
            !useMoreInfoMessage
          );
        }

        return EMPTY;
      })
    );
  }

  updateCorrectionRequestDimensions(useEffectiveDimensions: boolean, correctionRequestId: number): Observable<UpdateCorrectionRequestDimensionsForDensityResp> {
    const rqst = {
      ...new UpdateCorrectionRequestDimensionsForDensityRqst(),
      useEffectiveHeightInd: useEffectiveDimensions
    }
    const path = {
      ... new UpdateCorrectionRequestDimensionsForDensityPath(),
      correctionRequestId
    }

    return this.inspectionsApiService.updateCorrectionRequestDimensionsForDensity(rqst, path).pipe(
      this.retryStrategyService.retryStrategy(
        // TODO Move default values to a common enum
        ShipmentDetailsService.DEFAULT_GET_TIMEOUT,
        ShipmentDetailsService.DEFAULT_RETRY_DELAY,
        ShipmentDetailsService.DEFAULT_RETRY_COUNT,
        ShipmentDetailsService.DEFAULT_LOADING_MESSAGE,
        'GET-DIMENSIONER-INSPECTION'
      ),
      distinctUntilChanged(),
      catchError((error) => {
        const useMoreInfoMessage: boolean = true;

        if (error.code === '500') {
          this.errorHandlingService.handleResponseError(
            error,
            ErrorMessageActions.GETTING,
            `dimensioner inspection`,
            useMoreInfoMessage
          );
        } else {
          this.errorHandlingService.handleResponseError(
            error,
            ErrorMessageActions.GETTING,
            `dimensioner inspection`,
            !useMoreInfoMessage
          );
        }

        return EMPTY;
      })
    );
  }
  
  reviewCorrectionAction(reviewComment:string, reviewStatusCd: CorrectionRequestReviewStatusCd, correctionRequestId: number): Observable<ReviewCorrectionRequestResp> {
    RequestValidator.validateNumberIsPositiveOrZeroInteger(correctionRequestId, 'Correction Request Id');
    RequestValidator.validateStringNotNullOrEmpty(reviewStatusCd, 'Review Status Code');

    const reqQuery: ReviewCorrectionRequestRqst = new ReviewCorrectionRequestRqst();
    reqQuery.reviewComment = reviewComment;
    reqQuery.reviewStatusCd = reviewStatusCd;
    
    const requestPath: ReviewCorrectionRequestPath = new ReviewCorrectionRequestPath();
    requestPath.correctionRequestId = correctionRequestId;

    const reviewEmplId$ = this.inspectionsApiService.loggedInUser().pipe(
      tap((user: User) => {
        RequestValidator.validateStringNotNullOrEmpty(user.employeeId, 'Reviewer Employee Id')
        reqQuery.reviewEmplid = user.employeeId;
      })
    );
    const reviewCorrectionRequest$ = this.inspectionsApiService.reviewCorrectionRequest(reqQuery, requestPath, ApiUtil.DEFAULT_DATA_OPTIONS).pipe(
      distinctUntilChanged(),
      catchError((error) => {
        this.errorHandlingService.handleResponseError(
          error,
          ErrorMessageActions.GETTING,
          `review correction`,
          error.code === '500'
        );

        return EMPTY;
      })
    );

    return reviewEmplId$.pipe(
      concatMap(() => reviewCorrectionRequest$)
    );
  }
}
