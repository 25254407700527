<div class="correction-information">
  <mat-expansion-panel [(expanded)]="isExpanded">
    <mat-expansion-panel-header>
      <mat-panel-title class="section-label"> Correction </mat-panel-title>
    </mat-expansion-panel-header>
    <div fxLayout="column" fxLayoutAlign="start stretch">
      <div fxLayout="row" fxLayoutAlign="stretch start">
        <div fxLayout="column" fxFlex="25">
          <div fxLayout="row" *ngIf="correctionInformation?.is75">
            <div fxFlex="100" style="margin-bottom: 20px;"></div> 
          </div>
          <div class="label">PRO</div>
          <div class="value" data-testid="pro">
            <a xpo-regular-link href="" (click)="onProLinkClicked($event)"
              [matTooltip]="'Navigate to '+proNbr?.formatProNumber()+' shipment details'">
              {{ proNbr?.formatProNumber() ?? '-' }}
            </a>
          </div>
        </div>
        <div fxLayout="column" fxFlex="25">
          <div *ngIf="correctionInformation?.is75" fxLayout="row" >
            <div fxFlex="100" style="margin-bottom: 20px;"></div> 
          </div>
          <div class="label">Status</div>
          <div class="value">
            {{ CorrectionReviewStatusText[correctionRequest?.reviewStatusCd] ?? '-' }}
          </div>
        </div>
        <div *ngIf="!correctionInformation?.is75" fxLayout="column" class="flexColumn" >
          <div fxLayout="row" fxLayoutAlign="stretch start">
            <div fxLayout="column" style="min-width: 100px;">
              <div class="label" >Revenue Impact</div>
              <div class="value">
                {{ correctionInformation?.revenue ?? '0' | currency}}
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="correctionInformation?.is75" fxLayout="column" class="flexColumn" >
          <div fxLayout="row" >
            <div class="label" >Revenue Impact</div>
          </div>
          <div fxLayout="row" fxLayoutAlign="stretch start">
            <div fxLayout="column" style="min-width: 100px;">
            <div class="label">Actual Dims</div>
            <div class="value">
              {{ correctionInformation?.revenue ?? '0' | currency}}
            </div>
          </div>
          </div>
        </div>
        <div *ngIf="correctionInformation?.is75" fxLayout="column" class="flexColumn" >
          <div fxLayout="row">
            <div class="label" >Revenue Impact</div>
          </div>
          <div fxLayout="row" fxLayoutAlign="stretch start">
            <div fxLayout="column" style="min-width: 120px;">
              <div class="label">75 Inch Rule Dims</div>
              <div class="value">
                {{ correctionInformation?.effectiveRevenue ?? '0' | currency}}
              </div>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex="25">
          <div *ngIf="correctionInformation?.is75" fxLayout="row" >
            <div fxFlex="100" style="margin-bottom: 20px;"></div> 
          </div>
          <div fxLayout="row">
            <div fxLayout="column" style="min-width: 60px;">
              <div class="label">Weight</div>
              <div class="value">
                {{ shipmentDetailsResp?.shipmentDetails?.totGrossWeight}}
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!correctionInformation?.is75" fxLayout="column" class="flexColumn" >
          <div fxLayout="row" fxLayoutAlign="stretch start">
            <div fxLayout="column" style="min-width: 60px;">
              <div class="label">Density</div>
              <div class="value">
                {{ correctionInformation?.density.toFixed(3)}}
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="correctionInformation?.is75" fxLayout="column" class="flexColumn" style="min-width: 90px;">
          <div fxLayout="row">
            <div class="label" >Density</div>
          </div>
          <div class="label">Actual Dims</div>
          <div class="value">
            {{ correctionInformation?.density.toFixed(3)}}
          </div>
        </div>
        <div *ngIf="correctionInformation?.is75" fxLayout="column" class="flexColumn" style="min-width: 120px;">
          <div fxLayout="row">
            <div class="label" >Density</div>
          </div>
          <div class="label">75 Inch Rule Dims</div>
          <div class="value">
            {{ correctionInformation?.effectiveDensity.toFixed(3)}}
          </div>
      </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="stretch start" style="padding-top: 50px;">
        <div fxLayout="column" fxFlex="25">
          <div class="label">Updated Date</div>
          <div class="value" data-testid="updated-date">
            {{ correctionRequest?.auditInfo?.updatedTimestamp | date: 'short' ?? '-' }}
          </div>
        </div>
        <div fxLayout="column" fxFlex="25">
          <div class="label">Comments</div>
          <div class="value" data-testid="comments">
            {{ correctionRequest?.requestorComment ?? '-' }}
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="stretch start">
        <div fxLayout="column" fxFlex="100">
          <div class="correction-information__comments">
            <div class="label">Review Comments</div>
            <mat-form-field>
              <textarea
                matInput
                placeholder=""
                maxlength="4000"
                [(ngModel)]="reviewComments"
                (focusout)="reviewCommentsChanged.emit(reviewComments)"
                [disabled]="isReviewCommentsFieldDisabled$ | async"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="2"
                cdkAutosizeMaxRows="100"
              ></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="stretch start">
        <div fxLayout="column" fxFlex="100">
          <xpo-card type="data" color="">
            <xpo-card-header>
              <xpo-card-title>General Information</xpo-card-title>
            </xpo-card-header>
          
            <xpo-card-content>
              <div>
                <a class="normal_height_row" xpo-regular-link href="" color="accent" (click)="onOpenCorrectionsApp($event)">Open Edge Corrections</a>
              </div>
              <div>
                <a class="normal_height_row" xpo-regular-link href="" color="accent" (click)="onOpenCorrectionRequestDialog($event)">Open Correction Request Details</a>
              </div>
              <div *ngIf="isRulesetAvailable" >
                <a class="normal_height_row" xpo-regular-link href="" color="accent" (click)="onOpenRulesets($event)">View Applied Rulesets</a>
              </div>
            </xpo-card-content>
          </xpo-card>
        </div>
        
      </div>
    </div>
  </mat-expansion-panel>
</div>