import { Injectable } from '@angular/core';
import {
  GetNextBusinessDayPath,
  GetNextBusinessDayQuery,
  GetNextBusinessDayResp,
  ListLocationFeaturesQuery,
  ListLocationFeaturesResp,
  ListOperationalServiceCentersQuery,
  ListOperationalServiceCentersResp,
  LocationApiService,
  ServiceCenter
} from '@xpo-ltl-2.0/sdk-location';
import { NextBusinessDayOperationCd } from '@xpo-ltl/sdk-common';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { ApiError } from '../../classes/error/api/api-error';
import { ApiUtil } from '../../classes/utils/api-util';
import { ErrorMessageActions } from '../../enums/error-message-actions.enum';
import { SnackBarHandlingService } from '../snack-bar-handling.service';
import { RequestValidator } from '../../validators/request.validator';

@Injectable({ providedIn: 'root' })
export class LocationApiWrapperService {
  private _isEverySicLocationLoaded = false;

  private sicLocationsSubject = new BehaviorSubject(<ServiceCenter[]>[]);
  sicLocations$ = this.sicLocationsSubject.asObservable();

  private readonly FEATURE_ON_VALUE = 'Y';

  constructor(private locationService: LocationApiService, private snackBarHandlingService: SnackBarHandlingService) {}

  get isEverySicLocationLoaded(): boolean {
    return this._isEverySicLocationLoaded;
  }

  get sicLocations(): ServiceCenter[] {
    return this.sicLocationsSubject.value;
  }

  listLocationFeatures(featureCode: string): Observable<ListLocationFeaturesResp> {
    const query: ListLocationFeaturesQuery = new ListLocationFeaturesQuery();
    query.featureCd = featureCode;
    query.settingValue = this.FEATURE_ON_VALUE;

    return this.locationService.listLocationFeatures(query).pipe(
      take(1),
      map((resp: ListLocationFeaturesResp) => {
        if (resp) {
          return resp;
        } else {
          throw new ApiError(`Received Feature Flag(${featureCode}) as: ${JSON.stringify(resp)}`);
        }
      })
    );
  }

  listOperationalServiceCenters(): Observable<ServiceCenter[]> {
    const que = new ListOperationalServiceCentersQuery();
    que.excludeSatellitesInd = false;

    return this.locationService.listOperationalServiceCenters(que).pipe(
      take(1),
      map((resp: ListOperationalServiceCentersResp) => {
        if (resp) {
          this._isEverySicLocationLoaded = true;
          this.sicLocationsSubject.next(resp.serviceCenters);
          return resp.serviceCenters;
        } else {
          throw new ApiError(`Received SIC locations as: ${JSON.stringify(resp)}`);
        }
      })
    );
  }

  getNextBusinessDay(
    sicCd: string,
    startDate: string,
    offsetDaysCount: number,
    nextBusinessDayOperationCd?: NextBusinessDayOperationCd
  ): Observable<GetNextBusinessDayResp> {
    RequestValidator.validateStringNotNullOrEmpty(sicCd, 'Sic Code');
    RequestValidator.validateStringNotNullOrEmpty(startDate, 'Filter Start Date');
    const pathParams: GetNextBusinessDayPath = new GetNextBusinessDayPath();
    const queryParams: GetNextBusinessDayQuery = new GetNextBusinessDayQuery();

    pathParams.sicCd = sicCd;
    queryParams.startDate = startDate;
    queryParams.nextBusinessDayOperationCd = nextBusinessDayOperationCd;
    queryParams.offsetDaysCount = offsetDaysCount;

    return this.locationService.getNextBusinessDay(pathParams, queryParams, ApiUtil.DEFAULT_DATA_OPTIONS).pipe(
      take(1),
      catchError((error) => {
        this.snackBarHandlingService.handleResponseError(error, ErrorMessageActions.GETTING, `Last Business Day`);
        throw new ApiError(`Error ${ErrorMessageActions.GETTING} Last Business Day`, error);
      })
    );
  }
}
