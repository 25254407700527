<mat-expansion-panel [(expanded)]="isExpanded">
  <mat-expansion-panel-header>
    <mat-panel-title class="section-label"> Inspection Corrections </mat-panel-title>
  </mat-expansion-panel-header>

  <mat-panel-title class="sub-label"> Submitted Corrections </mat-panel-title>
  <div *ngIf="shipmentDetails?.corrections?.size > 0; else noSubmittedCorrections">
    <table mat-table [dataSource]="shipmentDetails?.corrections.toArray()">
      <ng-container [matColumnDef]="CORRECTION_REQUEST_DIALOG_OPEN">
        <th mat-header-cell *matHeaderCellDef>Correction Request</th>
        <td mat-cell *matCellDef="let element">
          <button
            (click)="onOpenCorrectionRequestDetailClicked(element)"
            mat-button
            color="primary"
            matTooltip="Open Correction Request Details"
          >
            Open
          </button>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="SHORT_DESCRIPTION">
        <th mat-header-cell *matHeaderCellDef>Correction Type</th>
        <td mat-cell *matCellDef="let element">{{ element.shortDescription }}</td>
      </ng-container>

      <ng-container [matColumnDef]="STATUS_CODE">
        <th mat-header-cell *matHeaderCellDef>Correction Status</th>
        <td mat-cell *matCellDef="let element">{{ element.statusCd | toMessageFromCorrectionStatus }}</td>
      </ng-container>

      <ng-container [matColumnDef]="AUTO_CORRECTION_IND">
        <th mat-header-cell *matHeaderCellDef>Auto Correction</th>
        <td mat-cell *matCellDef="let element">{{ element.autoCorrectionInd }}</td>
      </ng-container>

      <ng-container [matColumnDef]="REVENUE_IMPACT_AMOUNT">
        <th mat-header-cell *matHeaderCellDef>Revenue Impact</th>
        <td class="currency" mat-cell *matCellDef="let element">{{ element.revenueImpactAmount | toUsCurrency }}</td>
      </ng-container>

      <ng-container [matColumnDef]="REQUESTER_FULL_NAME">
        <th mat-header-cell *matHeaderCellDef>Requestor Name</th>
        <td mat-cell *matCellDef="let element">{{ element.requestorFullName }}</td>
      </ng-container>

      <ng-container [matColumnDef]="SIC_CODE">
        <th mat-header-cell *matHeaderCellDef>Inspection SIC</th>
        <td mat-cell *matCellDef="let element">{{ element.sicCd }}</td>
      </ng-container>

      <ng-container [matColumnDef]="CREATED_TIMESTAMP">
        <th mat-header-cell *matHeaderCellDef>Submitted Date</th>
        <td mat-cell *matCellDef="let element">{{ element.auditInfo.createdTimestamp | dateMilitaryFormat }}</td>
      </ng-container>

      <ng-container [matColumnDef]="LAST_UPDATED_TIMESTAMP">
        <th mat-header-cell *matHeaderCellDef>Last Update</th>
        <td mat-cell *matCellDef="let element">{{ element.auditInfo.updatedTimestamp | dateMilitaryFormat }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsForSubmittedCorrections"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsForSubmittedCorrections"></tr>
    </table>
  </div>
  <ng-template #noSubmittedCorrections>
    <div class="no_data">No Submitted Corrections</div>
  </ng-template>

  <mat-panel-title class="sub-label__correctionRequest"> In Progress Corrections Requests </mat-panel-title>

  <div *ngIf="shipmentDetails?.correctionRequests?.size > 0; else noSubmittedCorrections">
    <table mat-table [dataSource]="shipmentDetails?.correctionRequests.toArray()">
      <ng-container [matColumnDef]="SHORT_DESCRIPTION">
        <th mat-header-cell *matHeaderCellDef>Correction Type</th>
        <td mat-cell *matCellDef="let element">{{ element.shortDescription }}</td>
      </ng-container>

      <ng-container [matColumnDef]="STATUS_CODE">
        <th mat-header-cell *matHeaderCellDef>Correction Status</th>
        <td mat-cell *matCellDef="let element">{{ element.statusCd }}</td>
      </ng-container>

      <ng-container [matColumnDef]="AUTO_CORRECTION_IND">
        <th mat-header-cell *matHeaderCellDef>Auto Correction</th>
        <td mat-cell *matCellDef="let element">{{ element.autoIndYesNoString }}</td>
      </ng-container>

      <ng-container [matColumnDef]="REQUESTER_FULL_NAME">
        <th mat-header-cell *matHeaderCellDef>Requestor Name</th>
        <td mat-cell *matCellDef="let element">{{ element.requestorFullName }}</td>
      </ng-container>

      <ng-container [matColumnDef]="SIC_CODE">
        <th mat-header-cell *matHeaderCellDef>Inspection SIC</th>
        <td mat-cell *matCellDef="let element">{{ element.parentInspectionSicCd }}</td>
      </ng-container>

      <ng-container [matColumnDef]="LAST_UPDATED_TIMESTAMP">
        <th mat-header-cell *matHeaderCellDef>Last Update</th>
        <td mat-cell *matCellDef="let element">{{ element.auditInfo.updatedTimestamp | dateMilitaryFormat }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsForInProgressCorrections"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsForInProgressCorrections"></tr>
    </table>
  </div>
</mat-expansion-panel>
