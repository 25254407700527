import { ProNumber } from '../../classes/pronumber';
import { PhotoUtils } from '../../classes/photos/photo-utils';

export class CameraState {
  photosTaken: number;
  spaceRemainingKB: number;
  allowSnapshots: boolean;
  isMemoryFull: boolean;
  cameraStatus: CameraStatus;
  errorHistory: CameraError[] = [];
  supportedConstraints: any;

  constructor() {}
}

export class CameraError {
  errorMessage: string;
  timeStamp: Date;
  stackCall: string;
}

export enum CameraStatus {
  READY = 'ready',
  NOT_READY = 'not ready',
  ERROR = 'error'
}
