<div class="shipment-details">
  <app-shipment-details-toolbar [shipmentDetails]="shipmentDetails"></app-shipment-details-toolbar>
  <app-overview [inspectionShipmentDetails]="inspectionShipmentDetailsResp"></app-overview>
  <div *ngIf="locationService.isCorrectionsFeatureAvailable$ | async">
    <app-inspection-corrections [shipmentDetails]="shipmentDetails"></app-inspection-corrections>
  </div>
  <app-location-movement [shipmentDetails]="shipmentDetails"></app-location-movement>
  <app-customer-instructions [inspectionShipmentDetails]="inspectionShipmentDetailsResp"></app-customer-instructions>
  <app-bill-details [shipmentDetails]="shipmentDetails"></app-bill-details>
  <app-pricing [shipmentDetails]="shipmentDetails"></app-pricing>
  <app-previous-inspection [inspectionShipmentDetails]="inspectionShipmentDetailsResp"></app-previous-inspection>
  <app-document-list
    [shipmentDetails]="shipmentDetails"
    [title]="DocumentListTitles.DOCUMENT"
    [docTypes]="documentDocTypes"
    [photos]="documentPhotos$ | async"
  ></app-document-list>
  <app-document-list
    [shipmentDetails]="shipmentDetails"
    [title]="DocumentListTitles.OPERATIONS_PHOTOS"
    [docTypes]="operationsPhotosDocTypes"
    [photos]="operationPhotos$ | async"
  ></app-document-list>
  <app-document-list
    [shipmentDetails]="shipmentDetails"
    [title]="DocumentListTitles.INSPECTIONS_PHOTOS"
    [docTypes]="inspectionsPhotosDocTypes"
    [photos]="inspectionPhotos$ | async"
  ></app-document-list>
</div>
